<template>
	<!-- eslint-disable -->
	<v-container
		v-if="hasPermission($route.meta.permission)"
		id="regular-tables"
		tag="section"
	>
		<v-row class="ma-0">
			<v-col cols="12" class=" px-0"
				><span class="text-h2">My Wallet</span></v-col
			>
		</v-row>
		<v-row class="ma-0">
			<v-col cols="12" sm="4" class="ps-0">
				<v-card min-height="97" class="pa-2"
					><v-card-title class="pa-0 font-weight-bold"
						>Monthly credit limit</v-card-title
					>
					<v-card-title style="colr:black" class="pa-0">
						{{ formatTocurrency(monthly_credit_limit) }} SAR</v-card-title
					>
					<div class="d-flex float-end px-3">
						<b class="grey--text"
							>Consumed
							<span style="color: black"
								>{{ formatTocurrency(consumed) }} SAR</span
							></b
						>
					</div>
					<v-progress-linear
						rounded
						:value="(consumed * 100) / monthly_credit_limit"
						color="black"
					></v-progress-linear>
				</v-card>
			</v-col>
			<!-- <v-col cols="3">
				<v-card class="pa-2">
					<div class="d-flex">
						<v-card-title class="me-auto">Balance</v-card-title>
						<v-tooltip top color="white">
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon text v-bind="attrs" v-on="on" class="mx-3">
									<v-icon color="grey">mdi-information-outline</v-icon>
								</v-btn>
							</template>
							<span class="blue--text">Balance</span>
							<p style="color: black">is the monthly credit limit-consumer</p>
						</v-tooltip>
					</div>
					<v-card-title> {{ formatTocurrency(balance) }} RS</v-card-title>
					<v-progress-linear
						rounded
						value="0"
						color="yellow"
					></v-progress-linear>
				</v-card>
			</v-col> -->
			<v-col cols="12" sm="4">
				<v-card min-height="97" class="pa-2">
					<v-card-title class=" me-auto font-weight-bold" style="color:purple"
						>Total Available</v-card-title
					>

					<v-card-title>
						{{
							formatTocurrency(
								monthly_credit_limit + advance_payments - consumed
							)
						}}
						SAR</v-card-title
					>
				</v-card>
			</v-col>

			<v-col cols="12" sm="4">
				<v-card min-height="97" class="pa-2">
					<div class="d-flex">
						<v-card-title class="me-auto font-weight-bold"
							>Limit + Advance</v-card-title
						>
						<v-tooltip class="pa-3 " top color="white">
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon text v-bind="attrs" v-on="on" class="mx-3">
									<v-icon color="grey">mdi-information-outline</v-icon>
								</v-btn>
							</template>
							<h3 style="color:black">
								Limit {{ formatTocurrency(monthly_credit_limit) }} SAR
							</h3>
							<br />
							<h3 style="color: black">
								Advance {{ formatTocurrency(advance_payments) }} SAR
							</h3>
						</v-tooltip>
					</div>
					<v-card-title>
						{{ formatTocurrency(monthly_credit_limit + advance_payments) }}
						SAR</v-card-title
					>
				</v-card>
			</v-col>
		</v-row>

		<v-row class="ma-0">
			<v-col cols="12" class="ps-0 pe-sm-2 me-auto" sm="3" xl="2">
				<v-menu
					ref="delivery_date_menu"
					:v-model="dateRangeMenue"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					min-width="auto"
					offset-y
					dense
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="dateRangeText"
							label="Pick a date range"
							readonly
							range
							class="white"
							dense
							prepend-inner-icon="mdi-calendar"
							:append-icon="dateRangeText && 'mdi-close'"
							@click:append="ClearDateFilter"
							outlined
							hide-details
							v-bind="attrs"
							v-on="on"
						>
							>
						</v-text-field>
					</template>
					<v-date-picker
						class="ma-0"
						color="munjz-accent"
						v-model="dates"
						range
						dense
					>
						<template>
							<v-btn text color="primary" @click="ClearDateFilter">
								Clear
							</v-btn>
							<v-btn text color="primary" @click="dateRangeMenue = false">
								OK
							</v-btn>
						</template>
					</v-date-picker>
				</v-menu>
			</v-col>
			<!-- <v-col cols="12" sm="3" class="pe-0"
				><v-btn color="primary" class="me-0 float-right"
					><v-icon class="me-2">mdi-wallet-plus-outline</v-icon>Recharge
					walllet</v-btn
				></v-col> -->
		</v-row>

		<v-row class="mx-0 my-6">
			<v-col cols="12" class="pa-0">
				<v-data-table
					:headers="headers"
					:items="walletLogs"
					:loading="loading"
					loading-text="Loading... Please wait"
					:sort-desc="true"
					class="elevation-0"
					:search="search"
				>
					<template v-slot:item.created_at="{ item }">
						{{ moment(item.created_at).format("D MMM Y") }}
					</template>

					<template v-slot:item.amount="{ item }">
						<h4 class="" v-if="item.is_add">
							{{ formatTocurrency(item.amount) }}
							<span style="color: grey">SAR</span>
						</h4>

						<h3 class="text-center" v-else>
							{{ item.amount }}
							<h4 style="color: grey">SAR</h4>
						</h3>
					</template>

					<template v-slot:item.image_url="{ item }">
						<a color="blue" target="_blank" :href="item.image_url">
							{{ item.image_file }}
						</a>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<!-- <v-alert border="left" color="red" class="lighten-2" dark>
        {{ $t("permission_denied") }}
      </v-alert> -->
		</v-row>
	</v-container>
	<v-container v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
/* eslint-disable */

import moment from "moment";

export default {
	data() {
		return {
			consumed: null,
			dateRangeMenue: false,
			dateFilterState: false,
			logged_user: null,
			search: "",
			balance: 0,
			date: null,
			dates: [],
			loading: true,
			advance_payments: 0,
			monthly_credit_limit: 0,
			moment: moment,
			walletLogs: [],
			token: localStorage.getItem("token"),
			walletFromDialog: false,
			walletFrom: null,
			walletToDialog: false,
			walletTo: null,
			headers: [
				{ text: "Date", value: "created_at" },
				{ text: "Amount", value: "amount" },
				{ text: "Reference", value: "image_url" }
			]
		};
	},

	computed: {
		dateRangeText() {
			return this.dates.join(" to ");
		}
	},
	watch: {
		dates: {
			handler() {
				this.getWalletLogs();
			},
			deep: true
		}
	},
	mounted() {},
	created() {
		this.logged_user = JSON.parse(localStorage.getItem("logged_user") || "[]");
		this.getWalletLogs();
	},

	methods: {
		ClearDateFilter() {
			this.dateRangeMenue = false;
			this.dates = [];
		},
		formatTocurrency(val) {
			if (val !== null)
				return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
		},
		getWalletLogs() {
			axios
				.get("/api/v3/b2b/partner/wallet-logs", {
					params: {
						partner_id: localStorage.getItem("userid"),
						from: this.dates[0],
						to: this.dates[1]
					},
					headers: { "X-Authorization": this.token }
				})
				.then(response => {
					if (response.data.statusCode == 200) {
						this.walletLogs = response.data.wallet_logs;
						this.monthly_credit_limit = response.data.monthly_credit_limit;
						this.advance_payments = response.data.advance_payments;
						this.balance = response.data.balance;

						//TOdo api call
						this.consumed = response.data.consumed_amount || 0;
					}
					this.overlay = false;
				})
				.catch(error => {
					console.log("Error1: ", error.response);
					this.overlay = false;
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}
};
</script>
<style>
/* .v-data-table table th {
  font-size: 16px !important;
  font-weight: bolder !important; */
/* background-color: #007bff;
        color: #fff !important;} */
</style>
